import { appVersion } from './app-version';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  languageSelection: false,
  env: 'local',
  version: appVersion,
  apiUrl: 'https://api-staging.viessmann.com/',
  legalAppId: '098c595e-959e-41f2-83c5-c12af9413bf2',
  earlyAccess: 'https://forms.gle/eBFsSPjU23A65RoK6',
  accountUrl:
    'https://account-staging.viessmann.com/personal-data?redirect=https://app.developer-staging.viessmann.com&originApp=Developer Portal',
  appUrl: 'https://app.developer-staging.viessmann.com',
  hiddenRedirectUris: [
    'https://app.developer-integration.viessmann.com',
    'https://app.developer-staging.viessmann.com',
    'https://app.developer.viessmann.com',
  ],
  app: {
    defaultLanguage: 'en',
    supportedLanguages: [
      { locale: 'de', flag: 'de' },
      { locale: 'en', flag: 'en' },
    ],
  },
  auth: {
    appId: 'developer-portal',
    baseUrl: 'https://api-staging.viessmann.com',
    registerBaseUrl: 'https://account-staging.viessmann.com',
    iamUrl: 'https://iaml2.viessmann.com',
  },
  http: {
    translationBaseUrl: 'https://api-staging.viessmann.com/translations/v2/',
    usersBaseUrl: 'https://api-staging.viessmann.com/users/v1/',
    legal: 'https://api-staging.viessmann.com/legal/v3/',
    purchases: 'https://api-staging.viessmann.com/purchases/v1/',
    clients: 'https://api-staging.viessmann.com/auth/v1/clients/',
    documentation: 'https://documentation-staging.viessmann.com',
    statusPage:
      'https://api-staging.viessmann.com/status/v1/apps/r80xp4hm40b8/summary',
  },
  globalHeader: {
    baseUrl: 'https://api-staging.viessmann.com',
    accountAppUrl: 'https://account-staging.viessmann.com',
  },
};
